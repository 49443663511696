import styled from 'styled-components';
import { ReactNode } from 'react';

export const MaskContainer = styled.div<{
  children?: ReactNode;
  className?: string;
}>`
  position: absolute;
  width: 0;
  height: 0;
  overflow-x: hidden;
`;
